import React , {Component} from 'react'

import Header from './Components/Header.react'
import Navbar from './Components/Navbar.react'
import Introduction from './Components/Introduction.react'
import SlideBanner from './Components/SlideBanner.react'
import Service from './Components/Service.react'
import Room from './Components/Room.react'
import Gallery from './Components/Gallery.react'
import Price from './Components/Price.react'
import Aboutus from './Components/Aboutus.react'
import Contact from './Components/Contact.react'
import Map from './Components/Map.react'
import Footer from './Components/Footer.react'

class App extends React.Component {
  render() {
    return (
      <>
        <Header />
        <Navbar />
        <SlideBanner />
        <Introduction />
        <Service />
        <Room />
        <Gallery />
        <Price />
        <Aboutus />
        <Contact />
        <Map />
        <Footer />
      </>
    );
  }
}
export default App;