import React from 'react'

export default class Footer extends React.Component {
    render() {
        return (
            <footer>
               <p>&copy; Copyright @2023 <a href="https://wynnsoftsolution.com/">wynnsoft solution</a></p>
            </footer>
        )
    }
}