import React from 'react'

export default class Map extends React.Component {
    render() {
        return (
            <section className="map-wrapper">
                <iframe className="map"   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d956.7425880265303!2d102.79887903684792!3d16.42633207034458!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3122619a1b94e62d%3A0x65f4f154f1476d73!2z4Lio4Li54LiZ4Lii4LmM4LiU4Li54LmB4Lil4Lic4Li54LmJ4Liq4Li54LiH4Lit4Liy4Lii4Li44LmA4Lil4LiE4LmA4Liu4LmJ4Liy4Liq4LmM4LmC4Liu4Lih4LmB4LiE4Lij4LmM4LiC4Lit4LiZ4LmB4LiB4LmI4LiZ!5e0!3m2!1sth!2sth!4v1697095052344!5m2!1sth!2sth" loading="lazy" data-aos="fade-up"></iframe>
            </section>
        )
    }
}