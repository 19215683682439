import React from 'react';

export default class Navbar extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            navbarMenuMobileActive: false
        }

        this.hdlClickedNavbarMenu = this.hdlClickedNavbarMenu.bind(this)
        this.hdlClickedNavbarMenuClose = this.hdlClickedNavbarMenuClose.bind(this)
        
    }

    hdlClickedNavbarMenu() {
        this.setState({
            navbarMenuMobileActive: !this.state.navbarMenuMobileActive
        })
    }

    hdlClickedNavbarMenuClose() {
        this.setState({
            navbarMenuMobileActive: false
        })
    }

    render() {
        const {navbarMenuMobileActive} = this.state;
        return (
            <nav className="navbar-wrapper">
                <div className="container">
                    <div className="navbar">
                        <a href="/" className="navbar-logo">
                            <img src="/logo/logo.png" />
                        </a>

                        <a onClick={this.hdlClickedNavbarMenu} className="navbar-hambuger"><i className="fas fa-bars"></i></a>
                        
                        <ul className={`navbar-menu ${navbarMenuMobileActive?'navbar-menu-active':''}`}>
                            <a className="close-menu" onClick={this.hdlClickedNavbarMenuClose}><i className="fas fa-times"></i></a>

                            <li className="nav-item"><a href="#lakehouse">LAKEHOUSE</a></li>
                            <li className="nav-item"><a href="#services">SERVICES</a></li>
                            <li className="nav-item"><a href="#room">ROOM</a></li>
                            <li className="nav-item"><a href="#price">PRICE</a></li>
                            <li className="nav-item"><a href="#aboutus">ABOUT US</a></li>

                            <div className="shadow" onClick={this.hdlClickedNavbarMenuClose}></div>
                        </ul>

                        <div className={`navbar-social ${navbarMenuMobileActive?'navbar-social-active':''}`}>
                           {/* <a href="https://line.me/ti/p/~0803815300" target="_blank"> <i className="fab fa-line"></i></a> */}
                            <a href="https://www.facebook.com/lakehousehomecare/" target="_blank"><i className="fab fa-facebook"></i></a>
                            <a href="#contact" className="btn-contact">CONTACT</a>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}