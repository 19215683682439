import React from 'react';

export default class Header extends React.Component {
    render() {
        return (
            <header className="header-wrapper">
                <div className="container">
                    <div className="header">
                        <div className="group">
                            <i className="fas fa-phone-volume"></i>
                            <span>โทรติดต่อ เลคเฮ้าส์โฮมแคร์ : 062-5194978, 093-2423666</span>
                        </div>
                        <div className="group">
                            <i className="fas fa-map-marker-alt"></i>
                            <span>หมู่บ้านเลคไซต์วิว 111/11 ม.12    ถ.เลียบบึงหนองโคตร  ต.บ้านเป็ด  อ.เมือง   จ.ขอนแก่น 40000</span>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}