import React from 'react'


export default class Gallery extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            galleryState: 'total',
            galleryList:
            {
                total: [
                    [
                        { id: 1, image: "/images/gallerys/1.jpg", group: "room" },
                        { id: 7, image: "/images/gallerys/7.jpg", group: "room" },
                        { id: 8, image: "/images/gallerys/8.jpg", group: "room" },
                        { id: 9, image: "/images/gallerys/9.jpg", group: "room" },
                        { id: 10, image: "/images/gallerys/10.jpg", group: "room" },
                        { id: 11, image: "/images/gallerys/11.jpg", group: "room" },
                    ],
                    [
                        { id: 12, image: "/images/gallerys/12.jpg", group: "room" },
                        { id: 3, image: "/images/gallerys/3.jpg", group: "outdoor" },
                        { id: 14, image: "/images/gallerys/14.jpg", group: "outdoor" },
                        { id: 15, image: "/images/gallerys/15.jpg", group: "outdoor" },
                        { id: 16, image: "/images/gallerys/16.jpg", group: "outdoor" },
                        { id: 17, image: "/images/gallerys/17.jpg", group: "outdoor" },
                    ],
                    [
                        { id: 2, image: "/images/gallerys/2.jpg", group: "care" },
                        { id: 4, image: "/images/gallerys/4.jpg", group: "care" },
                        { id: 5, image: "/images/gallerys/5.jpg", group: "care" },
                        { id: 6, image: "/images/gallerys/6.jpg", group: "care" },
                        { id: 13, image: "/images/gallerys/13.jpg", group: "care" },
                        { id: 18, image: "/images/gallerys/18.jpg", group: "care" },
                    ],
                    [
                        { id: 19, image: "/images/gallerys/19.jpg", group: "care" },
                        { id: 20, image: "/images/gallerys/20.jpg", group: "care" },
                        { id: 21, image: "/images/gallerys/21.jpg", group: "care" },
                        { id: 22, image: "/images/gallerys/22.jpg", group: "care" },
                        { id: 23, image: "/images/gallerys/23.jpg", group: "care" },
                        { id: 24, image: "/images/gallerys/24.jpg", group: "care" },
                    ],
                    [
                        { id: 25, image: "/images/gallerys/25.jpg", group: "care" },
                        { id: 26, image: "/images/gallerys/26.jpg", group: "care" },
                        { id: 27, image: "/images/gallerys/27.jpg", group: "care" },
                        { id: 28, image: "/images/gallerys/28.jpg", group: "care" },
                        { id: 29, image: "/images/gallerys/29.jpg", group: "care" },
                        { id: 30, image: "/images/gallerys/30.jpg", group: "care" },
                    ],
                    [
                        { id: 31, image: "/images/gallerys/31.jpg", group: "care" },
                        { id: 32, image: "/images/gallerys/32.jpg", group: "care" },
                        { id: 33, image: "/images/gallerys/33.jpg", group: "care" },
                        { id: 34, image: "/images/gallerys/34.jpg", group: "care" },
                        { id: 35, image: "/images/gallerys/35.jpg", group: "care" },
                        { id: 36, image: "/images/gallerys/36.jpg", group: "care" },
                    ],
                    [
                        { id: 37, image: "/images/gallerys/37.jpg", group: "care" },
                        { id: 38, image: "/images/gallerys/38.jpg", group: "care" }
                    ],
                ],
                room: [
                    [
                        { id: 1, image: "/images/gallerys/1.jpg", group: "room" },
                        { id: 7, image: "/images/gallerys/7.jpg", group: "room" },
                        { id: 8, image: "/images/gallerys/8.jpg", group: "room" },
                        { id: 9, image: "/images/gallerys/9.jpg", group: "room" },
                        { id: 10, image: "/images/gallerys/10.jpg", group: "room" },
                        { id: 11, image: "/images/gallerys/11.jpg", group: "room" },
                    ]
                    , [
                        { id: 12, image: "/images/gallerys/12.jpg", group: "room" },
                    ]
                ],
                outdoor: [
                    [
                        { id: 3, image: "/images/gallerys/3.jpg", group: "outdoor" },
                        { id: 14, image: "/images/gallerys/14.jpg", group: "outdoor" },
                        { id: 15, image: "/images/gallerys/15.jpg", group: "outdoor" },
                        { id: 16, image: "/images/gallerys/16.jpg", group: "outdoor" },
                        { id: 17, image: "/images/gallerys/17.jpg", group: "outdoor" },
                    ]
                ],
                care: [
                    [
                        { id: 2, image: "/images/gallerys/2.jpg", group: "care" },
                        { id: 4, image: "/images/gallerys/4.jpg", group: "care" },
                        { id: 5, image: "/images/gallerys/5.jpg", group: "care" },
                        { id: 6, image: "/images/gallerys/6.jpg", group: "care" },
                        { id: 13, image: "/images/gallerys/13.jpg", group: "care" },
                        { id: 18, image: "/images/gallerys/18.jpg", group: "care" },
                    ],
                    [
                        { id: 19, image: "/images/gallerys/19.jpg", group: "care" },
                        { id: 20, image: "/images/gallerys/20.jpg", group: "care" },
                        { id: 21, image: "/images/gallerys/21.jpg", group: "care" },
                        { id: 22, image: "/images/gallerys/22.jpg", group: "care" },
                        { id: 23, image: "/images/gallerys/23.jpg", group: "care" },
                        { id: 24, image: "/images/gallerys/24.jpg", group: "care" },
                    ],
                    [
                        { id: 25, image: "/images/gallerys/25.jpg", group: "care" },
                        { id: 26, image: "/images/gallerys/26.jpg", group: "care" },
                        { id: 27, image: "/images/gallerys/27.jpg", group: "care" },
                        { id: 28, image: "/images/gallerys/28.jpg", group: "care" },
                        { id: 29, image: "/images/gallerys/29.jpg", group: "care" },
                        { id: 30, image: "/images/gallerys/30.jpg", group: "care" },
                    ],
                    [
                        { id: 31, image: "/images/gallerys/31.jpg", group: "care" },
                        { id: 32, image: "/images/gallerys/32.jpg", group: "care" },
                        { id: 33, image: "/images/gallerys/33.jpg", group: "care" },
                        { id: 34, image: "/images/gallerys/34.jpg", group: "care" },
                        { id: 35, image: "/images/gallerys/35.jpg", group: "care" },
                        { id: 36, image: "/images/gallerys/36.jpg", group: "care" },
                    ],
                    [
                        { id: 37, image: "/images/gallerys/37.jpg", group: "care" },
                        { id: 38, image: "/images/gallerys/38.jpg", group: "care" },
                    ]
                ]
            }
        }
    }

    hdlClickedChangeGalleryState(s){
        this.setState({
            galleryState: s
        });
    }

    render() {

        let { galleryList, galleryState = 'total' } = this.state;
        return (
            <section className="gallery-wrapper">
                <div className="container">
                    <div className="gallery">
                        <h2 className="title" data-aos="fade-up">My Care</h2>
                        <p className="subtitle" data-aos="fade-up">ภาพบรรยากาศภายใน Lakehouse Homecare</p>
                        <div className="group-btn">
                            <button className="btn" id="btn-slide-total" onClick={() => this.hdlClickedChangeGalleryState('total')} data-aos="fade-up">ทั้งหมด</button>
                            <button className="btn" id="btn-slide-care" onClick={() => this.hdlClickedChangeGalleryState('care')} data-aos="fade-up">การดูแล</button>
                            <button className="btn" id="btn-slide-outdoor" onClick={() => this.hdlClickedChangeGalleryState('outdoor')} data-aos="fade-up">บริเวณภายนอก</button>
                            <button className="btn" id="btn-slide-room" onClick={() => this.hdlClickedChangeGalleryState('room')} data-aos="fade-up">ห้องพัก</button>
                        </div>
                        <div className="swiper" id="swiper">
                            <div className="swiper-wrapper">
                                {
                                    (galleryState)
                                        ?
                                        galleryList[galleryState].map((datas, i) => {
                                            return (
                                                <div className="swiper-slide" key={i}>
                                                    <div className="group-img">
                                                        {(datas) ? datas.map((d, j) => <img key={j} data-aos="fade-right" src={d.image} />) : 'a'}
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : ''
                                }
                            </div>
                            <div className="swiper-pagination"></div>

                            {/* <div className="swiper-button-prev"></div> */}
                            {/* <div className="swiper-button-next"></div> */}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}