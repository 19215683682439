import React from 'react'

export default class Introduction extends React.Component {
    render() {
        return (
            <section className="introduction-wrapper">
                <div className="container">
                    <div className="introduction">
                        <div className="left">
                            <h1 className="title" data-aos="fade-right">
                                <p>PRESIDENT'S</p>
                                <p>MESSAGE</p>
                            </h1>
                            <p className="subtitle" data-aos="fade-right"><i>เลคเฮ้าส์ โฮมแคร์</i></p>
                        </div>
                        <div className="right">
                            <p className="content" data-aos="fade-up">ในผู้สูงวัย เป็นวัยที่อ่อนล้าทั้งกำลังกาย และกำลังใจ ตลอดชีวิต 40 ปี  ต้องทำงานเพื่อสร้างความมั่นคงให้กับตนเองและครอบครัว ตลอดระยะเวลามากกว่า 40 ปี ที่ต้องเผชิญกับความร้อนหนาวจนบัดนี้เข้าสู่วัยชรา ต้องการมองหาใครมาดูแลตนเอง หวังพึ่งลูก ๆ เขาก็ลำบากกับครอบครัวเขา ครั้นหวังพึ่งคู่ครองก็แก่พอกัน ดิฉันจึงคิดว่าต้องมีศูนย์ดูแลที่ดีมีคุณภาพคอยดูแลแทนลูกๆ ให้สมกับชีวิตที่ทำงานเหนื่อยหนักมาตลอด พอแก่ตัวควรได้รับการดูแลเป็นอย่างดีด้วยความรัก ความเข้าใจ ความห่วงใย และความอบอุ่น ผู้สูงอายุจะได้มีความสุขเสมือนมีลูกๆ และญาติมิตร ดูแลอย่างใกล้ชิด</p>
                            <p className="content" data-aos="fade-up">เลคเฮ้าส์โฮมแคร์ขอทำหน้าที่แทนลูกๆ ดูแลคุณพ่อ คุณแม่ และคนในครอบครัวของท่าน เราใส่ใจในเรื่องการดูแล (Care) และฟื้นฟูสภาพกายใจ จิตวิญญาณให้ผู้สูงวัยมีพลัง มีเกียรติ ศักดิ์ศรี มีความสง่างามสมวัย  จากความห่วงใยของท่าน และความใส่ใจของเรา ท่านจะได้หมดห่วงคลายกังวล เพราะท่านได้เลือกสิ่งที่ดีสมบูรณ์แบบให้กับคนที่ท่านรัก และห่วงใย </p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}