import React from 'react'

import Swal from 'sweetalert2/dist/sweetalert2.js'

import 'sweetalert2/dist/sweetalert2.css'
import json5 from 'json5'

export default class Contact extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            fullname: '',
            email: '',
            message: '',
        }
    }
    hdlFullName(e) {
        this.setState({
            fullname: e.target.value
        })
    }
    hdlEmail(e) {
        this.setState({
            email: e.target.value
        })
    }
    hdlMessage(e) {
        this.setState({
            message: e.target.value
        })
    }
    hdlClickedSubmit() {
        const fullname = this.state.fullname;
        const email = this.state.email;
        const message = this.state.message;


        if(!fullname || !email || !message){
            Swal.fire({
                icon:"warning",
                title:"แจ้งเตือน!",
                text:"กรุณากรอกข้อมูลให้ครบด้วยนะคะ"
            });
            return false;
        }


        fetch(`/api/sendmail`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ fullname, email, message })
        });
        // const json = await res.json();
        // if (json.status) {
            Swal.fire({
                icon: "success",
                title: "ขอบคุณสำหรับการส่งข้อความ",
                text: "ระบบได้ทำการส่งข้อความแล้ว เราจะทำการตอบกลับโดยเร็วที่สุด",
                footer: "โทรติดต่อ เลคเฮ้าส์โฮมแคร์ : 062-5194978, 093-2423666",
            }).then(() => {
                this.setState({
                    fullname: '',
                    email: '',
                    message: '',
                })
            });
        // }
    }



    render() {

        let { fullname, email, message } = this.state;

        return (
            <section className="contact-wrapper" id="contact">
                <div className="container">
                    <div className="contact">
                        <h2 className="title" data-aos="fade-up">Contact</h2>
                        <p className="subtitle" data-aos="fade-up">สนใจติดต่อสอบถามได้ที่</p>
                        <div className="content">
                            <div className="left">
                                <div className="form">
                                    <label data-aos="fade-right">Send a Message</label>
                                    <input type="text" className="form-control" onChange={(e) => this.hdlFullName(e)} value={fullname} placeholder="Full Name" data-aos="fade-right" />
                                    <input type="email" className="form-control" onChange={(e) => this.hdlEmail(e)} value={email} placeholder="Email" data-aos="fade-right" />
                                    <textarea placeholder="Leave Your Message" onChange={(e) => this.hdlMessage(e)} value={message} data-aos="fade-right"></textarea>
                                    <button className="submit" type="button" onClick={() => this.hdlClickedSubmit()} data-aos="fade-right">Send</button>
                                </div>
                            </div>
                            <div className="right">
                                <div className="col-2">
                                    <div className="col-left" data-aos="fade-up">
                                        <p className="address">หมู่บ้านเลคไซต์วิว 111/11 ม.12    ถ.เลียบบึงหนองโคตร  ต.บ้านเป็ด  อ.เมือง   จ.ขอนแก่น 40000</p>
                                        <p className="phone">โทรติดต่อ เลคเฮ้าส์โฮมแคร์ : 062-5194978, 093-2423666</p>
                                    </div>
                                    <div className="col-right" data-aos="fade-up">
                                        <p className="address">(Lake Side View Village) 111 / 11 Moo 12 , Nong Khot Ban Ped Subdistrict, Muang Khonkaen, 40000</p>
                                        <p className="phone">Call: 062-5194978, 093-2423666</p>
                                    </div>
                                </div>
                                <div className="social" >
                                   {/*   <a href="https://line.me/ti/p/~0803815300" target="_blank" data-aos="fade-up"> <i className="fab fa-line"></i></a>  */}
                                    <a href="https://www.facebook.com/lakehousehomecare/" target="_blank" data-aos="fade-up"> <i className="fab fa-facebook"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}