import React from 'react'

export default class Price extends React.Component {
    render() {
        return (
            <section className="price-wrapper" id="price">
                <div className="container">
                    <div className="content">
                        <h2 className="title" data-aos="fade-up">PRICE</h2>
                        <p className="subtitle" data-aos="fade-up">มีทั้งหมด 2 ชั้น | 6 ห้องนอน 14 เตียง</p>
                        <div className="price-box" data-aos="fade-up">
                            <p>ราคาเริ่มต้น 27,000 บาท มีทั้งแบบ รายวัน / รายเดือน</p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}